export default {
    zh:{
        tt:{
            queryParam:{
                code:'汇款编号',
                code_placeholder:'请输入汇款编号',
                name:'汇款人名称',
                name_placeholder:'请输入汇款人名称',
                hkAccount:'汇款人账号',
                hkAccount_placeholder:'请输入汇款人账号',
                skAccount:'收款人账号',
                skAccount_placeholder:'请输入收款人账号',
                bz:'币种',
                bz_placeholder:'请选择币种',
                status:'状态',
                status_placeholder:'请选择状态',
                pubTime:'发布时间',
                pubTime_placeholder:'请选择发布时间',
            },
            columns:{
                F_CODE:'汇款编号',
                F_NAME:'汇款人名称',
                F_HR_AMOUNT:'汇入金额',
                F_BZ:'币种',
                F_DATE:'交易日期',
                F_PUB_USER_ID:'发布人',
                F_PUB_TIME:'发布时间',
                F_STATUS:'状态',
            },
            form:{
                code:'汇款编号',
                code_placeholder:'请输入汇款编号',
                code_rule: '请输入汇款编号',
                name:'汇款人名称',
                name_placeholder:'请输入汇款人名称',
                name_rule: '请输入汇款人名称',
                hkAccount:'汇款人账号',
                hkAccount_placeholder:'请输入汇款人账号',
                hkAccount_rule: '请输入汇款人账号',
                countryId:'汇款国家',
                countryId_placeholder:'请选择汇款国家',
                countryId_rule: '请选择汇款国家',
                hrAmount:'汇入金额',
                hrAmount_rule: '请选择汇入金额',
                skAccount:'收款人账号',
                skAccount_placeholder:'请输入收款人账号',
                skAccount_rule: '请输入收款人账号',
                date:'交易日期',
                date_placeholder:'请选择交易日期',
                date_rule: '请选择交易日期',
                bz:'币种',
                bz_placeholder:'请选择币种',
                bz_rule: '请选择币种',
                bankId:'收款银行',
                bankId_placeholder:'请选择收款银行',
                bankId_rule: '请选择收款银行',
                remark:'备注',
                remark_placeholder:'请输入备注',
            },
        }
    },
    en:{
        tt:{
            queryParam:{
                code:'汇款编号',
                code_placeholder:'请输入汇款编号',
                name:'汇款人名称',
                name_placeholder:'请输入汇款人名称',
                hkAccount:'汇款人账号',
                hkAccount_placeholder:'请输入汇款人账号',
                skAccount:'收款人账号',
                skAccount_placeholder:'请输入收款人账号',
                bz:'币种',
                bz_placeholder:'请选择币种',
                status:'状态',
                status_placeholder:'请选择状态',
                pubTime:'发布时间',
                pubTime_placeholder:'请选择发布时间',
            },
            columns:{
                F_CODE:'汇款编号',
                F_NAME:'汇款人名称',
                F_HR_AMOUNT:'汇入金额',
                F_BZ:'币种',
                F_DATE:'交易日期',
                F_PUB_USER_ID:'发布人',
                F_PUB_TIME:'发布时间',
                F_STATUS:'状态',
            },
            form:{
                code:'汇款编号',
                code_placeholder:'请输入汇款编号',
                code_rule: '请输入汇款编号',
                name:'汇款人名称',
                name_placeholder:'请输入汇款人名称',
                name_rule: '请输入汇款人名称',
                hkAccount:'汇款人账号',
                hkAccount_placeholder:'请输入汇款人账号',
                hkAccount_rule: '请输入汇款人账号',
                countryId:'汇款国家',
                countryId_placeholder:'请选择汇款国家',
                countryId_rule: '请选择汇款国家',
                hrAmount:'汇入金额',
                hrAmount_rule: '请选择汇入金额',
                skAccount:'收款人账号',
                skAccount_placeholder:'请输入收款人账号',
                skAccount_rule: '请输入收款人账号',
                date:'交易日期',
                date_placeholder:'请选择交易日期',
                date_rule: '请选择交易日期',
                bz:'币种',
                bz_placeholder:'请选择币种',
                bz_rule: '请选择币种',
                bankId:'收款银行',
                bankId_placeholder:'请选择收款银行',
                bankId_rule: '请选择收款银行',
                remark:'备注',
                remark_placeholder:'请输入备注',
            },
        }
    }
}