import TtCard from './TtCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import TtListUtil,{ITtListDataObj} from './ttListUtil';
import language from './ttLanguage'
export default defineComponent ({
    name: 'TtList',
    props: {
        //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:ITtListDataObj=reactive<ITtListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                /**
                 * 这里有点特殊，cardFrom会传入到ttCard里面，然后经过addOrLoad请求传入后台，这样就可以决定卡片上要显示哪些操作按钮，
                 * 默认值是TtList，表示从TtList列表进入的ttCard，就要显示操作按钮，比如保存删除等。
                 * 但是当tt认领的时候，也会打开TtList列表进行选择要认领的tt电汇单，也可以点击编辑按钮查看电汇单的详细信息，这个时候就不能显示
                 * ttCard上面的操作按钮了，于是出现了都是从TtList列表进入ttCard，然而却要求有时显示ttCard的操作按钮，有时不显示。怎么办？
                 * 于是设计如下：当通过tt认领进入TtList页面的时候，把cardFrom设置为空，这样在TtList列表点击编辑进入ttCard的时候，传入后台
                 * 的cardFrom就为空了，就不是TtList了，就不会显示卡片顶部的操作按钮了。
                 * */
                gridTitle:'TT电汇管理',
                cardFrom:props.engineParams?'':'TtList',//props.engineParams有值表示选择tt电汇单进行认领
                showRadio:props.engineParams?true:false,//props.engineParams有值表示选择tt电汇单进行认领
                queryParam: {
                    from:props.engineParams?props.engineParams.from:'',//传给后台
                },
                modelComp: TtCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/tt/pageData')
            },
            otherParams:{
                count:0,
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TtListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
                if(params && 'status'==params.comboId){
                    return [{value:0,label:'待发布'},{value:1,label:'已发布'},{value:2,label:'已认领'},{value:3,label:'已作废'}]
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }
        const gridLoaded=(res:any)=>{
            dataObj.otherParams.count=res.count;
        }
        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo,gridLoaded
        }
    }
});