import BaseBean from "@/utils/BaseBean";

export interface ITtCardDataObj {
    utilInst:TtCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class TtCardUtil extends BaseBean{
    public dataObj:ITtCardDataObj;

    constructor(proxy:any,dataObj:ITtCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.bankData=await this.utils.OrderProviderApi.getAllBank({});//查询所有银行
        this.dataObj.otherParams.countryData=await this.utils.OrderProviderApi.getAllCountry({});//查询所有国家
    }
    //发布
    public async submit():Promise<void>{
        this.utils.Tools.configBox({
            message:'发布之后将不能修改单据了，确定发布吗?',
            sureFn:async ()=> {
                let res=await this.utils.OrderProviderApi.ttSubmit({params:{id:this.dataObj.form.id}});
                if(res.result){
                    this.utils.Tools.success({message:'操作成功'});
                    await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
                }
            }
        });
    }
    //作废
    public async doInvalid():Promise<void>{
        this.utils.Tools.configBox({
            message:'确定作废吗?',
            sureFn:async ()=> {
                let res=await this.utils.OrderProviderApi.ttInvalid({params:{id:this.dataObj.form.id}});
                if(res.result){
                    this.utils.Tools.success({message:'操作成功'});
                    await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
                    this.proxy.engine.engineParams.ownerComp.queryHandler(false);
                }
            }
        });
    }
}