import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import TtCardUtil,{ITtCardDataObj} from './ttCardUtil';
export default defineComponent({
    name:'TtCard',
    title:'TT电汇',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ITtCardDataObj=reactive<ITtCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/tt')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                code: [utils.UtilPub.commonValidRule(proxy.$t('tt.form.code_rule'))],
                name: [utils.UtilPub.commonValidRule(proxy.$t('tt.form.name_rule'))],
                hkAccount: [utils.UtilPub.commonValidRule(proxy.$t('tt.form.hkAccount_rule'))],
                countryId: [utils.UtilPub.commonValidRule(proxy.$t('tt.form.countryId_rule'))],
                hrAmount: [utils.UtilPub.commonValidRule(proxy.$t('tt.form.hrAmount_rule'))],
                skAccount: [utils.UtilPub.commonValidRule(proxy.$t('tt.form.skAccount_rule'))],
                date: [utils.UtilPub.commonValidRule(proxy.$t('tt.form.date_rule'))],
                bz: [utils.UtilPub.commonValidRule(proxy.$t('tt.form.bz_rule'))],
                bankId: [utils.UtilPub.commonValidRule(proxy.$t('tt.form.bankId_rule'))],
            },
            otherParams:{
                cardFrom:'',//从哪个模块来
                unit:'$',//汇入金额单位
                bankData:[],//银行
                countryData:[],//国家
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TtCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'countryId'==params.comboId){
                    return dataObj.otherParams.countryData;
                }
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
                if(params && 'bankId'==params.comboId){
                    return dataObj.otherParams.bankData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('countryId'==selectId){}
            if('bz'==selectId){
                dataObj.otherParams.unit=newValue==0+''?'元':'$';
            }
            if('bankId'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.otherParams.unit=res.data.bz==0?'元':'$';
            if('/load'==addOrLoad){
                dataObj.disabled=(res.data.status!=0);
            }
            //记录从哪里进入信用证卡片
            dataObj.otherParams.cardForm=engineInst.engineParams.cardFrom;
        }
        //传入参数到后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        //发布
        const submitHandler=async ()=>{
            await dataObj.utilInst.submit();
        }
        //作废
        const invalidHandle=async ()=>{
            await dataObj.utilInst.doInvalid();
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,setParam,beforeSaveHandler,
            submitHandler,invalidHandle
         
        }
    }
});